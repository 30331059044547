import React, { useState, useEffect } from 'react';
import Header from './shared/Header1';
import Footer from './shared/Footer1';
import ScrollToTop from './shared/ScrollToTop';
import { Link } from 'react-router-dom';
import Loader from './shared/Loader';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import {Helmet} from "react-helmet";

function BuyTickets() {
    const [ticket, setTicket] = useState([]);
    const [isLoader, setIsLoader] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [getSEO, setGetSEO] = useState([]);

    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

    const getSEOData = () => {
        axios
          .get(`${appConstant.API_URL}/c/page_meta/buy_tickets`, params)
          .then((res) => {
            if (res.data.status === 200) {
                setGetSEO(res.data.data);
            }
          })
          .catch((error) => {
          });
    };

    // ticket api
    const getTicketData = () => {
        axios.get(`${appConstant.API_URL}/c/ticket`, params).then(res => {
            setIsLoader(false);
            if (res?.data?.status === 200) {
                res.data.data.forEach((item, i) => {
                    item.isDisplay = (i === 0);
                });
                setTicket(res.data.data);
            } else {
                setTicket([]);
            }
        }).catch(error => {
            setIsLoader(false);
        });
    }

    useEffect(() => {
        getTicketData();
        getSEOData();
    }, []);

    // Function to toggle active class
    const toggleActive = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
        let data = ticket.map(item => ({ ...item, isDisplay:false}));
        data[index].isDisplay = true;
        setTicket(data);
    }

    return (
        <>
            <Helmet>
                <title>{getSEO?.meta_title || 'Buy Tickets'}</title>
                <meta name="description" content={getSEO?.meta_description} />
                <meta name="keywords" content={getSEO?.meta_keyword} />
                <meta
                    property="og:title"
                    content={getSEO?.meta_title}
                />
                <meta
                    property="og:image"
                    content={`${appConstant.CDN_URL}/assets/images/logo-black.png`}
                />
            </Helmet>
            <ScrollToTop />
            <Header />
            <div className='ticketsPage'>
                <div className='ticketsPage-flex'>
                    <div className='ticketsPage-column-1'>
                        <h3 className='heading'>BUY TICKETS</h3>
                        <p className='paragraph ff-bold'>{appConstant.EVENT_DATE}<br />12Pm - 11Pm <br />Agra</p>
                        <p className='paragraph'>Choose Your Experience at Jashnaa.</p>
                        {/* <ul className='footer-links'>
                            <li className='icons'><a href={`${appConstant.FACEBOOK_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/facebook.png`} alt='bg' /></a></li>
                            <li className='icons'><a href={`${appConstant.TWITTER_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/twitter.png`} alt='bg' /></a></li>
                            <li className='icons'><a href={`${appConstant.INSTAGRAM_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/instagram.png`} alt='bg' /></a></li>
                            <li className='icons'><a href={`${appConstant.YOUTUBE_URL}`} target='_blank' rel="noreferrer"><img src={`${appConstant.CDN_URL}/assets/images/youtube.png`} alt='bg' /></a></li>
                        </ul> */}
                    </div>
                    <div className='ticketsPage-column-2'>
                        <div className='ticketsPage-flex'>
                            {isLoader ? (
                                <Loader />
                            ) : (
                                <>
                                    {ticket.length > 0 &&
                                        <>
                                            <div className='ticketsPage-column-3'>
                                                <div className='tickets-card-flex'>
                                                    {ticket.map((res, i) => {
                                                        return (
                                                            <div className={`tickets-card-columns ${activeIndex === i ? ' active' : ''}`} key={'ticket' + i} onClick={() => toggleActive(i)}>
                                                                <h6 className='paragraph orange-text tt-uppercase ff-bold'>{res.title}</h6>
                                                                <h6 className='ticket-page-title'>inr {res.price}/day</h6>
                                                                <h6 className='paragraph'>{res.description}</h6>
                                                                <button className='orange-button button-small'>Know More</button>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            {ticket.map((res, i) =>
                                            <>
                                                {res.isDisplay && 
                                                    <div className='ticketsPage-column-4' key={'benefits' + i}>
                                                        <h6 className='paragraph ff-bold tt-uppercase'>{res.title} BENIFITS</h6>
                                                        {res.benefits.length > 0 && 
                                                            <>
                                                            <ul className='ticket-benefits'>
                                                                {res.benefits.map((result, m)=>{
                                                                    return(<li className='benefit-item' key={'benefits' + m + i}>{result}</li>)
                                                                })}
                                                            </ul>
                                                            <Link to={'/checkout/' + (res.id)} className='orange-button button-small d-inline-block'><img className='button-icon' src={`${appConstant.CDN_URL}/assets/images/plane.png`} alt="icons" />Proceed</Link>
                                                            </>
                                                        }                                                        
                                                    </div>
                                                }   
                                                </>                                        
                                            )}
                                        </>
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BuyTickets;
