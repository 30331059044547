import React from 'react';
import Header from './shared/Header1';
import Footer from './shared/Footer1';
import ScrollToTop from './shared/ScrollToTop';
import appConstant from './shared/constant/constant.json';

function PaymentMessage() {
    return (
        <>
        <ScrollToTop/>
        <Header/>
        <section className='tickets d-block internal-page'>
                <img className='payment-icon' src={`${appConstant.CDN_URL}/assets/images/success.png`} alt='bg'/>
                <h3 className='heading text-center mb-0'>PAYMENT CONFIRMED</h3>
                <p className='paragraph text-center ff-bold'>Your reservation is confirmed. We'll send you an email with reservation and payment details</p> 
                <div className='text-center button-top'>
                    <button className='orange-button'><img className='button-icon' src={`${appConstant.CDN_URL}/assets/images/plane.png`} alt='icon' />GET IN TOUCH</button>
                </div>  
                <div className='payment-details'>
                    <div className='ticket-detail-card'>
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph ff-bold tt-uppercase mb-0 theme-blue-text'>LOUNGE TICKET</h6>
                                <h6 className='paragraph mb-0'><small>1 Ticket</small></h6>
                            </div>
                            <div>
                                <h6 className='paragraph ff-bold tt-uppercase mb-0 orange-text'>INR 7000/DAY </h6>
                            </div>
                        </div>
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph mb-0'>
                                {appConstant.EVENT_DATE}<br/>
                                    12Pm - 11Pm<br/>
                                    Agra
                                </h6>
                            </div>
                        </div>
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph mb-0'>Category LT(7000): </h6>
                            </div>
                            <div>
                                <h6 className='paragraph mb-0'>1 Ticket</h6>
                            </div>
                        </div>
                    </div>
                    <div className='ticket-detail-card border-transparent'>
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph mb-0'>Sub Total:</h6>
                            </div>
                            <div>
                                <h6 className='paragraph mb-0'>INR 7000</h6>
                            </div>
                        </div>
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph mb-0'>Booking Fee:</h6>
                            </div>
                            <div>
                                <h6 className='paragraph mb-0'>INR 100</h6>
                            </div>
                        </div>
                        <div className='detail-card-flex confirm'>
                            <div>
                                <h6 className='paragraph mb-0 ff-bold green-text'>Confirmation No.</h6>
                            </div>
                            <div>
                                <h6 className='paragraph mb-0  ff-bold green-text'>69874</h6>
                            </div>
                        </div>
                        <div className='detail-card-flex'>
                            <div>
                                <h6 className='paragraph mb-0 ff-bold theme-bold-text'>Total Amount:</h6>
                            </div>
                            <div>
                                <h6 className='paragraph mb-0  ff-bold theme-bold-text'>INR 7100</h6>
                            </div>
                        </div>
                    </div>                  
                </div>  
        </section>
        <Footer/>
        </>
    );
}
export default PaymentMessage;