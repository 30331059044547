import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './Home';
import BuyTickets from './BuyTickets';
import Sponsor from './Sponsor';
import Exhibitor from './Exhibitor';
import Checkout from './Checkout';
import Treats from './Treats';
import Drinks from './Drinks';
import Artists from './Artists';
import PaymentMessage from './PaymentMessage';
import ContactUs from './ContactUs';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
const currentVersion = '1.0.1';
const savedVersion = localStorage.getItem('appVersion');
if (savedVersion !== currentVersion) {
  caches.keys().then(names => {
    names.forEach(name => {
      caches.delete(name);
    });
  });
  localStorage.setItem('appVersion', currentVersion);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/buy-tickets" element={<BuyTickets />}></Route>
      <Route exact path="/sponsor" element={<Sponsor />}></Route>
      <Route exact path="/exhibitor" element={<Exhibitor />}></Route>
      <Route exact path="/checkout/:slug" element={<Checkout />}></Route>
      <Route exact path="/treats" element={<Treats />}></Route>
      <Route exact path="/drinks" element={<Drinks />}></Route>
      <Route exact path="/artists" element={<Artists />}></Route>
      <Route exact path="/payment-message" element={<PaymentMessage />}></Route>
      <Route exact path="/contact-us" element={<ContactUs />}></Route>
      <Route path="*" element={<Navigate to="/" />}></Route>
    </Routes>
  </BrowserRouter>
);
reportWebVitals();